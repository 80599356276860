.drag-active {
    // TODO: pull MUI color variables
    border-color: #1769AA !important;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #1769AA;
        opacity: 0.1;
    }
}
// TODO: workaround till MUI is replaced
.ant-picker-dropdown {
    z-index: 3350 !important;
}

.badge-unset {
    display: inline-flex !important;
    line-height: unset !important;
}

.ant-drawer .ant-drawer-content-wrapper{
    margin: 18px;
    border-radius: 10px;
}

.ant-drawer .ant-drawer-content-wrapper>.ant-drawer-content{
    border-radius: 10px;
}